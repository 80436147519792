<template>
    <div :style="{ height: '100%', display: 'flex', flexDirection: 'column' }">

        <!-- toolbar -->
        <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '10px' }">
            <template slot="tools">
                <el-button type="primary" @click="$refs.applyCreateProduct.Open({ product_order_id: id })">添加产品</el-button>
            </template>
        </uw-grid-search>

        <!-- 数据表 -->
        <div :style="{ flex: '1' }">
            <vxe-table border :data="table" height="100%">

                <!-- 供应商 -->
                <vxe-column min-width="200" title="供应商">
                    <template #default="{ row }">
                        <el-span type="primary" @click.native="$refs.supplierDetails.Open(row.product.supplier?.id)" :title="row.product.supplier?.name" />
                    </template>
                </vxe-column>

                <!-- 产品名称 -->
                <vxe-column min-width="120" title="产品名称">
                    <template #default="{ row }">
                        <el-span type="primary" @click.native="$refs.productDetails.Open(row.product.id)" :title="row.product.name" />
                    </template>
                </vxe-column>

                <vxe-column min-width="100" field="product.type.name" title="产品类别"></vxe-column>
                <vxe-column min-width="100" field="product.brand" title="产品品牌"></vxe-column>

                <!-- 规格参数 -->
                <vxe-column min-width="200" title="规格参数">
                    <template #default="{ row }">
                        {{ row.product.parameter?.toString() }}
                    </template>
                </vxe-column>

                <!-- 采购数量 -->
                <vxe-column min-width="80" title="采购数量">
                    <template #default="{ row }">
                        {{ row.num }} / {{ row.product.unit }}
                    </template>
                </vxe-column>

                <!-- 采购单价 -->
                <vxe-column min-width="80" title="采购单价">
                    <template #default="{ row }">
                        {{ $lodash.toMoney(row.money) }}
                    </template>
                </vxe-column>

                <!-- 合计金额 -->
                <vxe-column min-width="90" title="合计金额">
                    <template #default="{ row }">
                        {{ $lodash.toMoney(row.money_total) }}
                    </template>
                </vxe-column>

                <vxe-column min-width="70" field="product.maintenance" title="保修期"></vxe-column>
                <vxe-column min-width="70" field="describe" title="备注说明"></vxe-column>

                <!-- 折扣 -->
                <vxe-column min-width="90" title="折扣">
                    <template #default="{ row }">
                        {{ row.discount }} %
                    </template>
                </vxe-column>

                <!-- 整单折扣 -->
                <vxe-column min-width="90" title="整单折扣">
                    <template #default="{ row }">
                        {{ row.discount_document }} %
                    </template>
                </vxe-column>

                <!-- 整单折后价 -->
                <vxe-column min-width="100" title="整单折后价">
                    <template #default="{ row }">
                        {{ $lodash.toMoney(row.money_discount) }}
                    </template>
                </vxe-column>
                
                <!-- 功能 -->
                <vxe-column width="80" align="center" fixed="right">
                    <template #default="{ row }">
                        <el-link type="primary" :underline="false" @click="$refs.applyUpdateProduct.Open(row.id)" :style="{ marginRight: '10px' }">编辑</el-link>
                        <el-link type="danger"  :underline="false" @click="Delete(row.id)">移除</el-link>
                    </template>
                </vxe-column>
            </vxe-table>

            <!-- 组件 -->
            <ims-order-apply-create-product ref="applyCreateProduct" @onChange="TableSearch" />
            <ims-order-apply-update-product ref="applyUpdateProduct" @onChange="TableSearch" />
            <ims-product-drawer ref="productDetails" @onChange="TableSearch" />
            <ims-supplier-drawer ref="supplierDetails" @onChange="TableSearch" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            table: []
        }
    },

    watch: {
        id: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },
    
    methods: {
        TableSearch() {
            this.$http.post(
                '/9api/ims/product-order-log/search-all',
                {
                    search: { product_order_id: this.id, state: 0 },
                    append: ['product', 'pay_type']
                }
            ).then(rps => {
                this.table = rps.data
                this.$emit('onChange')
            })
        },

        Delete(id) {
            this.$http.post('/9api/ims/product-order-log/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>