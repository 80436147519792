<template>
    <div>
        <!-- 金额概览 -->
        <el-card class="box-card">
            <div slot="header">
                <span><i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>采购金额</span>
            </div>
            <uw-statistic :column="4" gutter="10px">
                <uw-statistic-item money type="primary" title="采购金额(实)" unit="元" :value="order.money_actual"></uw-statistic-item>
                <uw-statistic-item money title="采购金额(估)" unit="元" :value="order.money_estimate"></uw-statistic-item>
                <uw-statistic-item title="采购日期" :value="order.date"></uw-statistic-item>
                <uw-statistic-item title="创建人" :value="order.created_user?.name"></uw-statistic-item>
            </uw-statistic>
        </el-card>

        <!-- 审核流程 -->
        <workflow-approve-card class="w-margin-t-10" v-model="order.workflow_id" @onChange="Search" />

        
        <div :style="{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }">
            <div>
                <el-card class="box-card" :style="{ marginTop: '20px', height: 'calc(100% - 20px)' }">
                    <div slot="header">
                        <span><i class="el-icon-data-board" :style="{ marginRight: '5px', color: '#0027ff' }"></i>订单详情</span>
                    </div>
                    <el-descriptions :column="1">
                        <el-descriptions-item label="关联合同">
                            <el-link type="warning" v-if="!order.contract?.id">未关联</el-link>
                            <span class="el-span-primary" v-if="order.contract?.id" @click="$refs.contractDrawer.Open(order.contract?.id)">
                                {{ order.contract?.number + '-' + order.contract?.name }}
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="采购日期">{{ order.date }}</el-descriptions-item>
                        <el-descriptions-item label="采购备注">{{ order.describe }}</el-descriptions-item>
                        <el-descriptions-item label="交货时间"></el-descriptions-item>
                        <el-descriptions-item label="交货地址">{{ order.delivery_address }}</el-descriptions-item>
                        <el-descriptions-item label="交货联系人">{{ order.delivery_name }}</el-descriptions-item>
                        <el-descriptions-item label="交货联系人电话">{{ order.delivery_phone }}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{ order.created_user?.name }}</el-descriptions-item>
                        <el-descriptions-item label="更新人">{{ order.updated_user?.name }}</el-descriptions-item>
                        <el-descriptions-item label="创建日期">{{ order.created_at }}</el-descriptions-item>
                        <el-descriptions-item label="更新日期">{{ order.updated_at }}</el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </div>
            
            <div>
                <crm-contract-card-base :style="{ marginTop: '20px' }" :id="order.contract_id" />
                <crm-contract-card-money :style="{ marginTop: '20px' }" :id="order.contract_id" />
            </div>

        </div>

        <!-- 组件 -->
        <crm-contract-drawer ref="contractDrawer" />

    </div>
</template>

<script>
export default {

    props: ['id'],

    data() {
        return {
            order: {
                id: null,
                files_id: [],
                created_user: {},
                updated_user: {},
                product_order_log: []
            }
        }
    },

    watch: {
        id: {
            handler (val) {
                if (val) {
                    this.Search()
                }
            },
            immediate: true
        }
    },

    methods: {

        // 订单 - 查询
        Search() {
            this.$http.post('/9api/ims/product-order/search-first', { search: { id: this.id }, append: ['created_user', 'contract'] }).then(rps => {
                this.order = rps.data
            })
        }
    }
}

</script>

