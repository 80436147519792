<template>
    <div>

        <!-- 菜单 -->
        <div :style="{ marginBottom: '10px' }">
            <projects-flow-reset-task :project-id="projects_id" @onChange="TeamSearch" />
            <el-button type="primary" size="mini" @click="$refs.user.Open()">添加成员</el-button>
        </div>

        <!-- 数据 -->
        <vxe-table ref="xTable" :data="team" :loading="loading" border="inner">

            <vxe-column width="50" type="seq" title="ID"></vxe-column>

            <!-- 成员 -->
            <vxe-column width="150" field="name" title="成员">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" :src="row.user?.avatar">{{ row.user?.name[0] }}</el-avatar>
                        {{  row.user?.name }}
                    </div>
                </template>
            </vxe-column>

            <!-- 角色 -->
            <vxe-column width="120" field="role" title="角色">
                <template #default="{ row }">
                    <el-link type="danger" v-if="row.role == 'admin'">管理员</el-link>
                    <el-link type="primary" v-if="row.role == 'sale'">销售</el-link>
                    <el-link type="primary" v-if="row.role == 'leader'">项目经理</el-link>
                    <el-link type="info" v-if="row.role == 'helper'">普通组员</el-link>
                    <el-link type="primary" v-if="row.role == 'affair'">商务</el-link>
                    <el-link type="primary" v-if="row.role == 'presale'">售前</el-link>
                </template>
            </vxe-column>

            <!-- 人工计价/P -->
            <vxe-column width="120" field="money" title="人工计价/P">
                <template #default="{ row }">
                    <el-link :underline="false" type="warning" @click="$refs.userEditMoney.Open(projects_id, row.user_id, row.money)">￥{{ row.money }}</el-link>
                </template>
            </vxe-column>

            <!-- 人工成本 -->
            <vxe-column width="120" field="consume_days" title="成本统计">
                <template #default="{ row }">
                    ￥{{ row.money * (Number(row.wo_sum_day_consume) + Number(row.projects_sum_day_consume)) }}
                </template>
            </vxe-column>

            <!-- 任务消耗/P -->
            <vxe-column width="120" field="projects_sum_day_consume" title="任务消耗/P">
                <template #default="{ row }">
                    <el-link :underline="false" type="primary" icon="el-icon-pie-chart" @click="$refs.dialogFlow.Open({ project_id: projects_id, user_id: row.user_id, stage: ['任务完成', '任务转交'] })">
                        {{ row.projects_sum_day_consume }}
                    </el-link>
                </template>
            </vxe-column>

            <!-- 消耗工时 -->
            <vxe-column width="120" field="wo_sum_day_consume" title="工单消耗/P">
                <template #default="{ row }">
                    <el-link :underline="false" type="primary" icon="el-icon-pie-chart" @click="$refs.dialogWo.Open({ projects_id: projects_id, user_id: row.user_id })">
                        {{ row.wo_sum_day_consume }}
                    </el-link>
                </template>
            </vxe-column>

            <!-- 消耗工时 -->
            <vxe-column width="120" title="小计/P">
                <template #default="{ row }">
                    <el-link :underline="false" icon="el-icon-pie-chart">
                        {{ (Number(row.wo_sum_day_consume) + Number(row.projects_sum_day_consume)).toFixed(2) }}
                    </el-link>
                </template>
            </vxe-column>

            <!-- 加入日期 -->
            <vxe-column width="160" field="created_at" title="加入日期">
                <template #default="{ row }">
                    <el-link icon="el-icon-time">{{ row.created_at }}</el-link>
                </template>
            </vxe-column>

            <!-- 更新日期 -->
            <vxe-column width="160" field="updated_at" title="更新日期">
                <template #default="{ row }">
                    <el-link icon="el-icon-time">{{ row.updated_at }}</el-link>
                </template>
            </vxe-column>

            <!-- 操作 -->
            <vxe-column width="80"  title="操作" align="center" fixed="right">
                <template #default="{ row }">
                    <el-link @click="TeamDelete(row.id)" type="danger">移除</el-link>
                </template>
            </vxe-column>

        </vxe-table>

        <!-- 组件 -->
        <hrm-employee-select-table ref="user" @onChange="UserChange" width="1080px" height="65%" multiple />
        <userEditMoney ref="userEditMoney" @onChange="TeamSearch()"></userEditMoney>
        <pm-dialog-table-flow           ref="dialogFlow"    @onChange="TeamSearch()" />
        <pm-dialog-table-wo-day-log     ref="dialogWo"      @onChange="TeamSearch()" />
    </div>
</template>

<script>
import userEditMoney from '@/views/projects/DialogProjectsTeamEditMoney.vue'

export default {
    props: ['projects_id'],

    components: {
        userEditMoney,
    },

    data () {
        return {
            // 窗口
            loading: false,

            // 数据
            team: [],
        }
    },

    watch: {
        projects_id: {
            handler (val) {
                if (val) {
                    this.TeamSearch()
                }
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 项目成员 - 检索
        |--------------------------------------------------
        |
        */

        TeamSearch() {

            // 切换加载状态
            this.loading = true

            // 检索请求
            this.$http.post('/9api/projects-team/search', { project_id: this.projects_id, append: ['user', 'projects_sum_day_consume', 'wo_sum_day_consume'] }).then(rps => {
                
                this.team = rps.data

                // 切换加载状态
                this.loading = false
            })
        },


        /**
        |--------------------------------------------------
        | 项目成员 - 删除
        |--------------------------------------------------
        | *id (int) 合同ID
        |
        */

        TeamDelete(id) {

            // 切换加载状态
            this.loading = true

            // 移除请求
            this.$http.post('/9api/projects-team/delete', { project_id: this.projects_id, id: id }).then(rps => {
                this.TeamSearch()
            })
        },

        /**
        |--------------------------------------------------
        | 项目成员 - 添加
        |--------------------------------------------------
        |
        */

        UserChange(users) {

            // 切换加载状态
            this.loading = true

            // 移除请求
            this.$http.post('/9api/projects-team/create', { project_id: this.projects_id, user_ids: users.map(item => item.id) }).then(rps => {
                this.TeamSearch()
            })
        }
    }
}
</script>