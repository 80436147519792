<template>
    <div :style="{ height: '100%', display: 'flex', flexDirection: 'column' }">

        <!-- toolbar -->
        <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '10px' }">
            <template slot="tools">
                <el-button type="primary" icon="el-icon-plus" @click="$refs.orderCreate.open({ type: 'contract', contract_id: contract_id })" >新建申请单</el-button>
            </template>
        </uw-grid-search>

        <!-- 数据表 -->
        <div :style="{ flex: '1' }">
            <el-empty v-if="!table || table.length == 0" description="暂无记录"></el-empty>

            <div v-for="(item, idx) in table" :key="idx">
                
                <!-- 订单信息 -->
                <div>
                    <!-- 订单编号 -->
                    <span :style="{ fontSize: '16px', fontWeight: 'bold' }" @click="$refs.orderDrawer.Open(item.id)" class="el-span-primary">
                        订单：{{ item.number }}
                    </span>

                    <!-- 订单状态 -->
                    <el-tag v-if="item.workflow_state == 0" size="mini" effect="dark" type="info">未审核</el-tag>
                    <el-tag v-if="item.workflow_state == 1" size="mini" effect="dark" type="warning">待审核</el-tag>
                    <el-tag v-if="item.workflow_state == 2" size="mini" effect="dark" >审核中</el-tag>
                    <el-tag v-if="item.workflow_state == 3" size="mini" effect="dark" type="danger">已拒绝</el-tag>
                    <el-tag v-if="item.workflow_state == 4" size="mini" effect="dark" type="success">已通过</el-tag>
                    <el-tag v-if="item.workflow_state == 5" size="mini" effect="dark" type="warning">待撤销</el-tag>
                    <el-tag v-if="item.workflow_state == 6" size="mini" effect="dark" type="warning">撤销中</el-tag>
                    <el-tag v-if="item.workflow_state == 7" size="mini" effect="dark" type="danger">撤销失败</el-tag>
                    <el-tag v-if="item.workflow_state == 8" size="mini" effect="dark" type="danger">已撤销</el-tag>
                </div>

                <!-- 其它信息 -->
                <div :style="{ marginTop: '5px' }">
                    <el-descriptions :column="3">
                        <el-descriptions-item label="采购金额(预)">
                            {{ item.money_estimate }}
                        </el-descriptions-item>
                        <el-descriptions-item label="采购日期">
                            {{ item.date }}
                        </el-descriptions-item>
                        <el-descriptions-item label="创建人">
                            {{ item.created_user.name }} - {{ item.created_at }}
                        </el-descriptions-item>
                        <el-descriptions-item label="采购金额(实)">
                            {{ item.money_actual }}
                        </el-descriptions-item>
                        <el-descriptions-item label="备注">
                            {{ item.describe ?? '未填写' }}
                        </el-descriptions-item>
                        <el-descriptions-item label="更新人">
                            {{ item.updated_user.name }} - {{ item.updated_at }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>

                <el-link :underline="false" :style="{ marginBottom: '10px' }">申请单：</el-link>
                <vxe-table :data="item.product_order_log.filter(element => !element.state)">

                    <!-- 供应商 -->
                    <vxe-column min-width="200" title="供应商">
                        <template #default="{ row }">
                            <el-span type="primary" @click.native="$refs.supplierDetails.Open(row.product.supplier?.id)" :title="row.product.supplier?.name" />
                        </template>
                    </vxe-column>

                    <!-- 产品名称 -->
                    <vxe-column min-width="120" title="产品名称">
                        <template #default="{ row }">
                            <el-span type="primary" @click.native="$refs.productDetails.Open(row.product.id)" :title="row.product.name" />
                        </template>
                    </vxe-column>

                    <vxe-column min-width="100" field="product.type.name" title="产品类别"></vxe-column>
                    <vxe-column min-width="100" field="product.brand" title="产品品牌"></vxe-column>

                    <!-- 规格参数 -->
                    <vxe-column min-width="200" title="规格参数">
                        <template #default="{ row }">
                            {{ row.product.parameter?.toString() }}
                        </template>
                    </vxe-column>

                    <!-- 采购数量 -->
                    <vxe-column min-width="80" title="采购数量">
                        <template #default="{ row }">
                            {{ row.num }} / {{ row.product.unit }}
                        </template>
                    </vxe-column>

                    <!-- 采购单价 -->
                    <vxe-column min-width="80" title="采购单价">
                        <template #default="{ row }">
                            {{ $lodash.toMoney(row.money) }}
                        </template>
                    </vxe-column>

                    <!-- 合计金额 -->
                    <vxe-column min-width="90" title="合计金额">
                        <template #default="{ row }">
                            {{ $lodash.toMoney(row.money_total) }}
                        </template>
                    </vxe-column>

                    <vxe-column min-width="70" field="product.maintenance" title="保修期"></vxe-column>
                    <vxe-column min-width="70" field="describe" title="备注说明"></vxe-column>

                    <!-- 折扣 -->
                    <vxe-column min-width="90" title="折扣">
                        <template #default="{ row }">
                            {{ row.discount }} %
                        </template>
                    </vxe-column>

                    <!-- 整单折扣 -->
                    <vxe-column min-width="90" title="整单折扣">
                        <template #default="{ row }">
                            {{ row.discount_document }} %
                        </template>
                    </vxe-column>

                    <!-- 整单折后价 -->
                    <vxe-column min-width="100" title="整单折后价">
                        <template #default="{ row }">
                            {{ $lodash.toMoney(row.money_discount) }}
                        </template>
                    </vxe-column>
                    
                    <!-- 功能 -->
                    <vxe-column width="80" align="left" fixed="right">
                        <template #header>
                            <el-span type="primary" @click.native="$refs.applyCreateProduct.Open({ product_order_id: item.id })" title="添加" />
                        </template>
                        <template #default="{ row }">
                            <el-link type="primary" :underline="false" @click="$refs.applyUpdateProduct.Open(row.id)" :style="{ marginRight: '10px' }">编辑</el-link>
                            <el-link type="danger"  :underline="false" @click="Delete(row.id)">移除</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>

                <el-link :underline="false" :style="{ margin: '10px 0' }">订货单：</el-link>
                <vxe-table :data="item.product_order_log.filter(element => element.state)" :style="{ marginBottom: '20px' }">

                    <!-- 供应商 -->
                    <vxe-column min-width="200" title="供应商">
                        <template #default="{ row }">
                            <el-span type="primary" @click.native="$refs.supplierDetails.Open(row.product.supplier?.id)" :title="row.product.supplier?.name" />
                        </template>
                    </vxe-column>
                    
                    <!-- 产品名称 -->
                    <vxe-column min-width="120" title="产品名称">
                        <template #default="{ row }">
                            <el-span type="primary" @click.native="$refs.productDetails.Open(row.product.id)" :title="row.product.name" />
                        </template>
                    </vxe-column>

                    <vxe-column min-width="100" field="product.type.name" title="产品类别"></vxe-column>
                    <vxe-column min-width="100" field="product.brand" title="产品品牌"></vxe-column>
                    
                    <!-- 规格参数 -->
                    <vxe-column min-width="200" title="规格参数">
                        <template #default="{ row }">
                            {{ row.product.parameter?.toString() }}
                        </template>
                    </vxe-column>

                    <!-- 采购数量 -->
                    <vxe-column min-width="80" title="采购数量">
                        <template #default="{ row }">
                            {{ row.num }} / {{ row.product.unit }}
                        </template>
                    </vxe-column>

                    <vxe-column min-width="80" field="pay_date" title="付款日期"></vxe-column>
                    <vxe-column min-width="80" field="pay_type.name" title="付款方式"></vxe-column>
                    <vxe-column min-width="80" field="taxation_rate" title="发票税率"></vxe-column>
                    <vxe-column min-width="80" field="arrive_date" title="到货周期"></vxe-column>

                    <!-- 采购单价 -->
                    <vxe-column min-width="80" title="采购单价">
                        <template #default="{ row }">
                            {{ $lodash.toMoney(row.money) }}
                        </template>
                    </vxe-column>

                    <!-- 合计金额 -->
                    <vxe-column min-width="90" title="合计金额">
                        <template #default="{ row }">
                            {{ $lodash.toMoney(row.money_total) }}
                        </template>
                    </vxe-column>

                    <vxe-column min-width="100" field="product.maintenance" title="保修期"></vxe-column>
                    <vxe-column width="220" field="describe" title="备注说明"></vxe-column>

                    <!-- 折扣 -->
                    <vxe-column min-width="90" title="折扣">
                        <template #default="{ row }">
                            {{ row.discount }} %
                        </template>
                    </vxe-column>

                    <!-- 整单折扣 -->
                    <vxe-column min-width="90" title="整单折扣">
                        <template #default="{ row }">
                            {{ row.discount_document }} %
                        </template>
                    </vxe-column>

                    <!-- 整单折后价 -->
                    <vxe-column min-width="100" title="整单折后价">
                        <template #default="{ row }">
                            {{ $lodash.toMoney(row.money_discount) }}
                        </template>
                    </vxe-column>

                </vxe-table>

            </div>
        </div>

        <!-- 组件 -->
        <ims-order-create ref="orderCreate" @onChange="TableSearch" />
        <ims-order-drawer ref="orderDrawer" @onChange="TableSearch" />
        <ims-product-drawer ref="productDetails" @onChange="TableSearch" />
        <ims-supplier-drawer ref="supplierDetails" @onChange="TableSearch" />
        <ims-order-apply-create-product ref="applyCreateProduct" @onChange="TableSearch" />
        <ims-order-apply-update-product ref="applyUpdateProduct" @onChange="TableSearch" />
    </div>
</template>

<script>
export default {
    props: {
        contract_id: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            loading: false,
            table: [],
        }
    },

    watch: {
        id: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },
    
    methods: {
        TableSearch() {
            this.loading = true
            this.$http.post('/9api/ims/product-order/search-all', { search: { contract_id: this.contract_id }, append: ['created_user', 'updated_user', 'product_order_log'] }).then(rps => {
                this.table = rps.data
                this.loading = false
            })
        },

        Delete(id) {
            this.$http.post('/9api/ims/product-order-log/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>