export default {

    /**
     * 防抖功能
     * @param {Function} fn 回调的方法 
     * @param {Int} wait 延迟时间
     */
    debounceTimer: null,
    debounce(fn, wait)
    {
        if (this.debounceTimer !== null) {
            clearTimeout(this.debounceTimer)
        }
        this.debounceTimer = setTimeout(fn, wait)
    },


    /**
    |------------------------------------------------------------------------------------------
    |------------------------------------------------------------------------------------------
    |------------------------------------------------------------------------------------------
    | 时间处理 (fn => date_)
    |------------------------------------------------------------------------------------------
    |------------------------------------------------------------------------------------------
    |------------------------------------------------------------------------------------------
    |
    */

    /**
     * 日期(格式 2024-01-01)
     * @returns 
     */
    date()
    {
        let now = new Date()
        let year = now.getFullYear()
        let month = now.getMonth() + 1
        let date = now.getDate()
        
        // 将单个数字前面补零
        month = month < 10 ? '0' + month : month
        date = date < 10 ? '0' + date : date
        
        return year + '-' + month + '-' + date
    },

    /**
     * 日期计算
     * @param {String} type 'y|m|d'对应'年|月|日' 
     * @param {Int} value 需要增加或减去的时间
     * @returns 
     */
    date_math(type, value = 0)
    {
        // 获取时间
        let now = new Date()

        // 增加或减少日期
        switch(type)
        {
            case 'day':
                now.setDate(now.getDate() + value)
                break;
            case 'month':
                now.setMonth(now.getMonth() + value)
                break;
            case 'year':
                now.setFullYear(now.getFullYear() + value)
                break;
            default:
                break;
        }

        // 年-月-日(补0)
        let year = now.getFullYear()
        let month = (now.getMonth() + 1).toString().padStart(2,0)
        let date = (now.getDate()).toString().padStart(2,0)

        return year + '-' + month + '-' + date
    },

    /**
     * 日期范围
     * @param {String} type 'y|w|m|q'对应'年|季|月|周' 
     * @param {*} value 需要增加或减去的时间
     */
    date_range(type, value = 0)
    {
        // 获取时间
        let now = new Date()
        // now.setMonth(11)
        let now_s = new Date(now)
        let now_e = new Date(now)

        // 天
        if (type == 'day')
        {
            now_s.setDate(now.getDate() + value)
            now_e.setDate(now.getDate() + value)
            return [
                now_s.getFullYear() + '-' + (now_s.getMonth() + 1).toString().padStart(2,0) + '-' + (now_s.getDate()).toString().padStart(2,0),
                now_e.getFullYear() + '-' + (now_e.getMonth() + 1).toString().padStart(2,0) + '-' + (now_e.getDate()).toString().padStart(2,0)
            ]
        }

        // 周
        if (type == 'week')
        {
            now_s.setDate(now.getDate() + (value * 7) - (now.getDay() - 1))
            now_e.setDate(now.getDate() + (value * 7) + (7 - now.getDay()))
            return [
                now_s.getFullYear() + '-' + (now_s.getMonth() + 1).toString().padStart(2,0) + '-' + (now_s.getDate()).toString().padStart(2,0),
                now_e.getFullYear() + '-' + (now_e.getMonth() + 1).toString().padStart(2,0) + '-' + (now_e.getDate()).toString().padStart(2,0)
            ]
        }

        // 月
        if (type == 'month')
        {
            now_s.setMonth(now.getMonth() + value)
            now_e.setMonth(now.getMonth() + value + 1)
            now_s.setDate(1)
            now_e.setDate(0)
            return [
                now_s.getFullYear() + '-' + (now_s.getMonth() + 1).toString().padStart(2,0) + '-' + (now_s.getDate()).toString().padStart(2,0),
                now_e.getFullYear() + '-' + (now_e.getMonth() + 1).toString().padStart(2,0) + '-' + (now_e.getDate()).toString().padStart(2,0)
            ]
        }

        // 季
        if (type == 'quarter')
        {
            if ([0, 1, 2].includes(now.getMonth()))
            {
                console.log(now.getMonth())
                now_s.setMonth(0)
                now_e.setMonth(3)
            }
            if ([3, 4, 5].includes(now.getMonth()))
            {
                console.log(now.getMonth())
                now_s.setMonth(3)
                now_e.setMonth(6)
            }
            if ([6, 7, 8].includes(now.getMonth()))
            {
                now_s.setMonth(6)
                now_e.setMonth(9)
            }
            if ([9, 10, 11].includes(now.getMonth()))
            {
                now_s.setMonth(9)
                now_e.setMonth(12)
            }
            now_s.setMonth(now_s.getMonth() + value * 3)
            now_e.setMonth(now_e.getMonth() + value * 3)
            now_s.setDate(1)
            now_e.setDate(0)
            return [
                now_s.getFullYear() + '-' + (now_s.getMonth() + 1).toString().padStart(2,0) + '-' + (now_s.getDate()).toString().padStart(2,0),
                now_e.getFullYear() + '-' + (now_e.getMonth() + 1).toString().padStart(2,0) + '-' + (now_e.getDate()).toString().padStart(2,0)
            ]
        }

        // 季
        if (type == 'year')
        {
            now_s.setFullYear(now.getFullYear() + value)
            now_e.setFullYear(now.getFullYear() + value)
            now_s.setMonth(0)
            now_e.setMonth(12)
            now_s.setDate(1)
            now_e.setDate(0)
            return [
                now_s.getFullYear() + '-' + (now_s.getMonth() + 1).toString().padStart(2,0) + '-' + (now_s.getDate()).toString().padStart(2,0),
                now_e.getFullYear() + '-' + (now_e.getMonth() + 1).toString().padStart(2,0) + '-' + (now_e.getDate()).toString().padStart(2,0)
            ]
        }
    },

    /**
     * 日期时间(格式 2024-01-01 00:00:00)
     * @returns 
     */
    date_time()
    {
        let now = new Date();
        let year = now.getFullYear()
        let month = now.getMonth() + 1
        let date = now.getDate()
        let hour = now.getHours()
        let minute = now.getMinutes()
        let second = now.getSeconds()
        
        // 将单个数字前面补零
        month = month < 10 ? '0' + month : month
        date = date < 10 ? '0' + date : date
        hour = hour < 10 ? '0' + hour : hour
        minute = minute < 10 ? '0' + minute : minute
        second = second < 10 ? '0' + second : second
        
        return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
    },


    /**
    |------------------------------------------------------------------------------------------
    |------------------------------------------------------------------------------------------
    |------------------------------------------------------------------------------------------
    | 数组处理 (fn => array_)
    |------------------------------------------------------------------------------------------
    |------------------------------------------------------------------------------------------
    |------------------------------------------------------------------------------------------
    |
    */

    /**
     * 对数组进行求合运算
     * @param {array|Object} data  对象或者数组
     * @param {Int} fixed 保留的小数位
     * @param {String} column 指定对象的键名
     * @returns 
     */
    array_sum(data, fixed = 0, column = false)
    {
        if (column)
        {
            data = data.map(rps => rps[column])
        }
        let sum = data.reduce((accumulator, currentValue) => Number(accumulator)  +  Number(currentValue), 0)
        return sum.toFixed(fixed)
    },
    
    getMaxZIndex() {
        const allElement = Array.from(document.all);
        const zIndexArray = []
        allElement.forEach((item) => {
            zIndexArray.push(Number(window.getComputedStyle(item, null).getPropertyValue("z-index")) || 0)
        })
        const maxZIndex = Math.max(...zIndexArray)
        return maxZIndex + 1
    },

    toMoney(value, precision = 2)
    {
        return Number(value).toLocaleString('zh', { style: 'currency', currency: 'CNY', minimumFractionDigits: precision, maximumFractionDigits:precision})
    }
    
}